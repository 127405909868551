<template>
  <page-view>
    <div class="oem_order_header">
      <a-row :gutter="48" :style="{ paddingLeft: '24px' }">
        <a-col :lg="5" :md="12" :sm="24" :style="{ padding: 0 }">
          <div class="filter_item">
            <span>订单号</span>
            <a-input placeholder="订单号" v-model.trim="queryParam.no" class="filter_input" />
          </div>
        </a-col>
        <a-col :lg="5" :md="12" :sm="24" :style="{ padding: 0 }">
          <div class="filter_item">
            <span>手机号</span>
            <a-input placeholder="用户手机号" v-model="queryParam.mobile" class="filter_input" />
          </div>
        </a-col>
        <a-col :lg="5" :md="12" :sm="24" :style="{ padding: 0 }">
          <div class="filter_item">
            <span>用户ID</span>
            <a-input placeholder="用户ID" v-model="queryParam.uid" class="filter_input" />
          </div>
        </a-col>
        <a-col :lg="5" :md="12" :sm="24" :style="{ padding: 0 }">
          <div class="filter_item">
            <span>分站ID</span>
            <a-input placeholder="分站ID" v-model="queryParam.oemUid" class="filter_input" />
          </div>
        </a-col>
      </a-row>
      <a-row :gutter="48" :style="{ paddingLeft: '24px' }">
        <a-col :lg="5" :md="12" :sm="24" :style="{ padding: 0 }">
          <div class="filter_item">
            <span>推广人ID</span>
            <a-input placeholder="推广人ID" v-model="queryParam.inviterUid" class="filter_input" />
          </div>
        </a-col>
        <a-col :lg="5" :md="12" :sm="24" :style="{ padding: 0 }">
          <div class="filter_item">
            <span>备注</span>
            <a-input placeholder="备注" v-model="queryParam.comment" class="filter_input" />
          </div>
        </a-col>
        <a-col :lg="5" :md="12" :sm="24" :style="{ padding: 0, marginRight: '16px' }">
          <div class="filter_item">
            <span>查询时间</span>
            <a-range-picker
              class="filter_input"
              :value="[moment(queryParam.payTimeStart), moment(queryParam.payTimeEnd)]"
              @change="onChangeDatePay"
            />
          </div>
        </a-col>
        <a-col :lg="4" :md="12" :sm="24" :style="{ padding: 0 }">
          <div class="time_select">
            <span
              v-for="item in shortcutDate"
              :key="item.id"
              @click="handleShortcutDate(item)"
              :class="currentShortcutDate === item.date ? 'active' : ''"
              >{{ item.title }}</span
            >
          </div>
        </a-col>
        <a-col :lg="4" :md="12" :sm="24" :style="{ padding: 0 }">
          <div class="filter_item">
            <a-button type="primary" class="query_btn" @click="$refs.table.refresh(false)">查询</a-button>
            <a-button @click="resetQueryParam">重置</a-button>
          </div>
        </a-col>
      </a-row>
    </div>
    <div class="oem_data">
      <a-tabs :activeKey="activeChartTabKey" @change="handleChartTabChange" style="width:1617px">
        <a-tab-pane tab="实时概况" key="realTime"></a-tab-pane>
        <a-tab-pane tab="以往数据" key="previousData"></a-tab-pane>
        <span slot="tabBarExtraContent" v-if="activeChartTabKey == 'realTime'" class="tab_time">对比时间: {{ compareTimeTab }}</span>
        <span slot="tabBarExtraContent" v-else class="tab_time">更新时间: {{ updateTimeTab }}</span>
      </a-tabs>
      <!-- 实时 -->
      <div class="all_chart" v-if="activeChartTabKey == 'realTime'">
        <a-row :gutter="48">
          <a-col :md="5" :sm="24">
            <div class="chart_item">
              <div class="chart_info">
                <div class="num_title">网站总利润</div>
                <div class="num_total">
                  {{ orderCountTotal.incomeOem ? orderCountTotal.incomeOem : 0 }}
                </div>
              </div>
              <smoothLine
                :id="'chartWrapper1'"
                :lineStyle="{ width: '280px', height: '135px' }"
                :optionSeries="optionSeries1"
                :xAxisOption="xAxisOption"
                :tooltips="tooltips1"
                :grid="grid1"
              ></smoothLine>
            </div>
          </a-col>
          <a-col :md="5" :sm="24">
            <div class="chart_item">
              <div class="chart_info">
                <div class="num_title">下单用户数</div>
                <div class="num_total">
                  {{ orderCountTotal.uid ? orderCountTotal.uid : 0 }}
                </div>
              </div>
              <smoothLine
                :id="'chartWrapper2'"
                :lineStyle="{ width: '280px', height: '135px' }"
                :optionSeries="optionSeries2"
                :xAxisOption="xAxisOption"
                :tooltips="tooltips1"
                :grid="grid1"
              ></smoothLine>
            </div>
          </a-col>
          <a-col :md="5" :sm="24">
            <div class="chart_item">
              <div class="chart_info">
                <div class="num_title">子订单数</div>
                <div class="num_total">
                  {{ orderCountTotal.count ? orderCountTotal.count : 0 }}
                </div>
              </div>
              <smoothLine
                :id="'chartWrapper3'"
                :lineStyle="{ width: '280px', height: '135px' }"
                :optionSeries="optionSeries3"
                :xAxisOption="xAxisOption"
                :tooltips="tooltips1"
                :grid="grid1"
              ></smoothLine>
            </div>
          </a-col>
          <a-col :md="9" :sm="24">
            <div class="chart_rig_num">
              <div class="chart_info">
                <div class="num_title">用户总付款</div>
                <div class="num_total">
                  {{ orderCountTotal.amount ? orderCountTotal.amount : 0 }}
                </div>
              </div>
              <div class="chart_info">
                <div class="num_title">推广总利润</div>
                <div class="num_total">
                  {{ orderCountTotal.incomeInviter ? orderCountTotal.incomeInviter : 0 }}
                </div>
              </div>
              <div class="chart_info">
                <div class="num_title">下级分站利润</div>
                <div class="num_total">
                  {{ orderCountTotal.incomeOemP ? orderCountTotal.incomeOemP : 0 }}
                </div>
              </div>
            </div>
          </a-col>
        </a-row>
      </div>
      <!-- 以往 -->
      <div class="all_chart2" v-else>
        <div class="all_left">
          <div class="top_left">
            <div class="all_chart2_item">
              <div class="num_title2">网站总利润</div>
              <div class="num_total2">
                {{ orderCountTotal.incomeOem ? orderCountTotal.incomeOem : 0 }}
              </div>
            </div>
            <div class="all_chart2_item">
              <div class="num_title2">下单用户数</div>
              <div class="num_total2">
                {{ orderCountTotal.uid ? orderCountTotal.uid : 0 }}
              </div>
            </div>
            <div class="all_chart2_item">
              <div class="num_title2">子订单数</div>
              <div class="num_total2">
                {{ orderCountTotal.count ? orderCountTotal.count : 0 }}
              </div>
            </div>
          </div>
          <div class="all_chart2_line">
            <smoothLine
              v-if="currentShortcutDate == 1"
              :id="'chartWrapper4'"
              :lineStyle="{ width: '100%', height: '206px' }"
              :optionSeries="optionSeries4"
              :xAxisOption="xAxisOption2"
              :tooltips="tooltips1"
              :legend="legend"
              :grid="grid2"
            ></smoothLine>
            <smoothLine
              v-else
              :id="'chartWrapper4'"
              :lineStyle="{ width: '100%', height: '200px' }"
              :optionSeries="optionSeries4"
              :xAxisOption="xAxisOption2"
              :tooltips="tooltip2"
              :legend="legend"
              :grid="grid2"
            ></smoothLine>
          </div>
        </div>
        <div class="all_right">
          <div class="right_item">
            <div class="num_title2">用户总付款</div>
            <div class="num_total2">
              {{ orderCountTotal.amount ? orderCountTotal.amount : 0 }}
            </div>
          </div>
          <div class="right_item">
            <div class="num_title2">推广总利润</div>
            <div class="num_total2">
              {{ orderCountTotal.incomeInviter ? orderCountTotal.incomeInviter : 0 }}
            </div>
          </div>
          <div class="right_item">
            <div class="num_title2">下级分站利润</div>
            <div class="num_total2">
              {{ orderCountTotal.incomeOemP ? orderCountTotal.incomeOemP : 0 }}
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="oem_table_wrapper">
      <a-tabs :activeKey="activeTabKey" @change="handleTabChange">
        <a-tab-pane :tab="array.name" v-for="array in orderStatusArray" :key="array.value"></a-tab-pane>
      </a-tabs>
      <div class="oem_table_box">
        <s-table ref="table" rowKey="id" :columns="columns" :data="dataSource">
          <span slot="serial" slot-scope="text, record, index">
            {{ index + 1 }}
          </span>

          <template slot="no" slot-scope="text, record">
            <a-tooltip placement="right" overlayClassName="tooltip_custom">
              <template slot="title">
                <span>点击复制</span>
              </template>
              <span
                class="uid_fat"
                @click="copyInfo(record.no)"
                @mouseover="handleMouseover(record, 'show1')"
                @mouseout="handleMouseout(record, 'show1')"
              >
                {{ text.substring(0, 4) + '……' + text.substring(16) }}
                <span class="uid_child" v-show="record.show1">{{ text }}</span>
              </span>
            </a-tooltip>
          </template>
          <template slot="uid" slot-scope="text, record">
            <a-tooltip placement="right" overlayClassName="tooltip_custom">
              <template slot="title">
                <span>点击复制</span>
              </template>
              <span
                class="uid_fat"
                @click="copyInfo(record.uid)"
                @mouseover="handleMouseover(record, 'show2')"
                @mouseout="handleMouseout(record, 'show2')"
              >
                {{ text.substring(0, 4) + '……' + text.substring(28) }}
                <span class="uid_child" v-show="record.show2">{{ text }}</span>
              </span>
            </a-tooltip>
          </template>
          <template slot="inviterUid" slot-scope="text, record">
            <a-tooltip placement="right" overlayClassName="tooltip_custom">
              <template slot="title">
                <span>点击复制</span>
              </template>

              <span
                class="uid_fat"
                @click="copyInfo(record.inviterUid)"
                @mouseover="handleMouseover(record, 'show3')"
                @mouseout="handleMouseout(record, 'show3')"
              >
                {{ text.substring(0, 4) + '……' + text.substring(28) }}
                <span class="uid_child" v-show="record.show3">{{ text }}</span>
              </span>
            </a-tooltip>
          </template>
          <template slot="oemUid" slot-scope="text, record">
            <a-tooltip placement="right" overlayClassName="tooltip_custom">
              <template slot="title">
                <span>点击复制</span>
              </template>

              <span
                class="uid_fat"
                @click="copyInfo(record.oemUid)"
                @mouseover="handleMouseover(record, 'show4')"
                @mouseout="handleMouseout(record, 'show4')"
              >
                {{ text.substring(0, 4) + '……' + text.substring(28) }}
                <span class="uid_child" v-show="record.show4">{{ text }}</span>
              </span>
            </a-tooltip>
          </template>
          <!-- 备注 -->
          <template slot="comment" slot-scope="text, record">
            <a-tooltip placement="right" overlayClassName="tooltip_custom" v-if="text">
              <template slot="title">
                <span>点击复制</span>
              </template>
              <span style="cursor: pointer" @click="copyInfo(record.comment)">{{
                text.trim().substring(0, 1) + '……' + text.charAt(text.length - 1)
              }}</span>
            </a-tooltip>
            <span v-else>-</span>
          </template>
          <template slot="picture" slot-scope="text, record">
            <img :src="text" @click="() => (preview = { show: true, image: text })" style="width: 45px; height: 45px; margin-right: 16px" />
            {{ record.goods.name }}
          </template>
          <template slot="status" slot-scope="text">
            <div
              class="circle"
              :class="
                text == 'UNPAID'
                  ? 'color1'
                  : '' || text == 'IN'
                  ? 'color2'
                  : '' || text == 'SUCCESS'
                  ? 'color3'
                  : '' || text == 'CANCEL'
                  ? 'color4'
                  : '' || text == 'ABNORMAL'
                  ? 'color5'
                  : ''
              "
            ></div>
            <span>{{ orderStatus[text] }}</span>
          </template>
          <template slot="amount" slot-scope="text">
            <span style="color: #f93244">{{ text }}</span>
          </template>
          <template slot="premiumPrice" slot-scope="text">
            <span style="color: #f93244">{{ text }}</span>
          </template>
          <template slot="incomeOem" slot-scope="text">
            <span style="color: #f93244">{{ text }}</span>
          </template>
          <template slot="incomeInviter" slot-scope="text">
            <span style="color: #f93244">{{ text }}</span>
          </template>
          <template slot="operation">-</template>
        </s-table>
      </div>
      <a-modal :visible="preview.show" :footer="null" @cancel="() => (preview.show = false)">
        <img style="width: 100%" :src="preview.image" />
      </a-modal>
    </div>
  </page-view>
</template>
<script>
import { STable } from '@/components';
import { PageView } from '@/layouts';
import { orderList, orderCount, orderClose, orderDetailClose } from '@api';
import { formatDate, orderStatus, jsonToArray, timestampToTime } from '@/utils/util';
import moment from 'moment';
import 'moment/locale/zh-cn';
moment.locale('zh-cn');
import SmoothLine from '@/components/SmoothLine';
export default {
  data() {
    return {
      activeChartTabKey: 'realTime',
      updateTimeTab: '',
      record: {},
      // showchilduid: false,
      orderStatus: orderStatus,
      activeTabKey: 'all',
      preview: { image: '', show: false },
      upload: false,
      originalData: '', // 编辑前的原始数据
      orderStatusArray: jsonToArray(orderStatus),
      payModal: false,
      currentShortcutDate: 0,
      orderCountTotal: {},
      queryParam: {
        payTimeStart: new Date(new Date().toLocaleDateString()).getTime(),
        payTimeEnd: new Date(new Date().toLocaleDateString()).getTime() + 24 * 60 * 60 * 1000
      },
      columns: [
        { title: '序号', scopedSlots: { customRender: 'serial' }, width: 80 },
        {
          title: '订单号',
          dataIndex: 'no',
          scopedSlots: { customRender: 'no' }
        },

        {
          title: '商品信息',
          dataIndex: 'goods.picture',
          scopedSlots: { customRender: 'picture' }
        },
        {
          title: '子订单数',
          dataIndex: 'count',
          customRender: (text) => text + '笔',
          width: 90
        },
        {
          title: '订单金额',
          dataIndex: 'amount',
          scopedSlots: { customRender: 'amount' },
          width: 100
        },
        {
          title: '溢价',
          dataIndex: 'premiumPrice',
          scopedSlots: { customRender: 'premiumPrice' },
          width: 65
        },
        {
          title: '网站利润',
          dataIndex: 'incomeOem',
          scopedSlots: { customRender: 'incomeOem' }
        },
        {
          title: '推广利润',
          dataIndex: 'incomeInviter',
          scopedSlots: { customRender: 'incomeInviter' }
        },
        {
          title: '订单状态',
          dataIndex: 'status',
          scopedSlots: { customRender: 'status' }
        },
        {
          title: '用户ID',
          dataIndex: 'uid',
          scopedSlots: { customRender: 'uid' }
        },
        {
          title: '推广人ID',
          dataIndex: 'inviterUid',
          scopedSlots: { customRender: 'inviterUid' }
        },
        {
          title: '分站ID',
          dataIndex: 'oemUid',
          scopedSlots: { customRender: 'oemUid' }
        },

        {
          title: '备注',
          dataIndex: 'comment',
          scopedSlots: { customRender: 'comment' }
        },
        {
          title: '更新时间',
          dataIndex: 'updateTime',
          customRender: formatDate
        },
        {
          title: '操作',
          dataIndex: 'createTime',
          scopedSlots: { customRender: 'operation' }
        }
      ],
      dataSource: (parameter) => {
        return orderList(Object.assign(parameter, this.queryParam), 3).then((result) => {
          this.originalData = ''; // 设置原始数据为空
          orderCount(this.queryParam, 3).then((res) => {
            this.orderCountTotal = res.data;
          });
          let range = '';
          if (this.currentShortcutDate == 0 || this.currentShortcutDate == 1) {
            range = 'hour';
          } else {
            range = 'day';
          }
          orderCount(Object.assign(parameter, this.queryParam, { range: range }), 3).then((res) => {
            this.formatLineData(res.data);
          });
          result.data.list.forEach((item) => {
            item.show1 = false;
            item.show2 = false;
            item.show3 = false;
            item.show4 = false;
          });
          return result.data;
        });
      },

      shortcutDate: [
        {
          id: 1,
          title: '今天',
          date: 0
        },
        {
          id: 2,
          title: '昨天',
          date: 1
        },
        {
          id: 3,
          title: '最近7天',
          date: 7
        },
        {
          id: 4,
          title: '最近30天',
          date: 30
        }
      ],

      // 今天/昨天 工具提示
      tooltips1: {
        trigger: 'axis',
        axisPointer: {
          type: 'line',
          lineStyle: {
            color: 'rgba(0,0,0,0.15)',
            width: 1,
            type: 'solid'
          }
        },
        formatter(params, ticket, callback) {
          let htmlStr = '';
          for (var i = 0; i < params.length; i++) {
            const param = params[i];
            const xName = param.name; //x轴的名称
            const seriesName = param.seriesName; //图例名称
            const value = param.value; //y轴值
            const color = param.color; //图例颜色

            if (i == 0) {
              if (xName < 10) {
                xName = '0' + xName;
              }
              htmlStr += `<span style="color:rgba(255,255,255,0.45)">${xName}:00~${xName}:59 <br/></span>`;
              htmlStr += '<div>';
              htmlStr += `<div style="width:4px;height:12px;background-color:${color};display:inline-block;margin-right:4px"></div>`;
              htmlStr += `<span style="color:rgba(255,255,255,0.65)">${seriesName}：<span style="color:rgba(255,255,255,1)">${value}</span></span>`;
              htmlStr += '</div>';
            } else {
              htmlStr += '<div>';
              htmlStr += `<div style="width:4px;height:12px;background-color:${color};display:inline-block;margin-right:4px"></div>`;
              htmlStr += `<span style="color:rgba(255,255,255,0.65)">${seriesName}：<span style="color:rgba(255,255,255,1)">${value}</span></span>`;

              htmlStr += '</div>';
            }
          }
          return htmlStr;
        }
      },
      tooltip2: {
        trigger: 'axis',
        axisPointer: {
          type: 'line',
          lineStyle: {
            // 直线指示器样式设置
            color: 'rgba(0,0,0,0.15)',
            width: 1,
            type: 'solid'
          }
        },
        formatter(params, ticket, callback) {
          let htmlStr = '';
          for (var i = 0; i < params.length; i++) {
            const param = params[i];
            const xName = param.name; //x轴的名称
            const seriesName = param.seriesName; //图例名称
            const value = param.value; //y轴值
            const color = param.color; //图例颜色
            const day = new Date(Date.parse(xName.replace(/-/g, '/')));
            const today = new Array('星期日', '星期一', '星期二', '星期三', '星期四', '星期五', '星期六'); //创建星期数组
            const weekD = today[day.getDay()]; //周几
            if (i == 0) {
              htmlStr += `<span style="color:rgba(255,255,255,0.45)">${xName} ${weekD}<br/></span>`;
              htmlStr += '<div>';
              htmlStr += `<div style="width:4px;height:12px;background-color:${color};display:inline-block;margin-right:4px"></div>`;
              htmlStr += `<span style="color:rgba(255,255,255,0.65)">${seriesName}：<span style="color:rgba(255,255,255,1)">${value}</span></span>`;
              htmlStr += '</div>';
            } else {
              htmlStr += '<div>';
              htmlStr += `<div style="width:4px;height:12px;background-color:${color};display:inline-block;margin-right:4px"></div>`;
              htmlStr += `<span style="color:rgba(255,255,255,0.65)">${seriesName}：<span style="color:rgba(255,255,255,1)">${value}</span></span>`;
              htmlStr += '</div>';
            }
          }
          return htmlStr;
        }
      },
      grid1: {
        top: '5px',
        left: '0',
        right: '0',
        bottom: '18px',
        containLabel: true
      },
      grid2: {
        top: '80px',
        left: '24px',
        right: '24px',
        bottom: '24px',
        containLabel: true
      },
      legend: {
        top: '2%',
        right: '2%',
        textStyle: {
          color: 'rgba(0,0,0,0.65)',
          fontSize: 12,
          fontFamily: 'PingFang SC'
        },
        itemWidth: 12,
        itemHeight: 12,
        data: [
          {
            name: '网站总利润',
            icon: 'rect'
          },
          {
            name: '下单用户数',
            icon: 'rect'
          },
          {
            name: '子订单数',
            icon: 'rect'
          }
        ]
      },
      xAxisOption: {
        xAxisData: [],
        xAxisInterval: 11
      },
      xAxisOption2: {
        xAxisData: []
      },
      optionSeries1: [
        {
          symbol: 'none',
          name: '今天',
          data: [],
          type: 'line',
          smooth: true,
          itemStyle: {
            color: '#5B8FF9'
          }
        },

        {
          symbol: 'none',
          name: '昨天',
          data: [],
          type: 'line',
          smooth: true,
          itemStyle: {
            color: '#CDDDFD'
          }
        }
      ],
      optionSeries2: [
        {
          symbol: 'none',
          name: '今天',
          data: [],
          type: 'line',
          smooth: true,
          itemStyle: {
            color: '#33C6F7'
          }
        },

        {
          symbol: 'none',
          name: '昨天',
          data: [],
          type: 'line',
          smooth: true,
          itemStyle: {
            color: '#C1EEFD'
          }
        }
      ],
      optionSeries3: [
        {
          symbol: 'none',
          name: '今天',
          data: [],
          type: 'line',
          smooth: true,
          itemStyle: {
            color: '#FFAF30'
          }
        },

        {
          symbol: 'none',
          name: '昨天',
          data: [],
          type: 'line',
          smooth: true,
          itemStyle: {
            color: '#FFE7C0'
          }
        }
      ],
      optionSeries4: [
        {
          symbol: 'none',
          name: '网站总利润',
          data: [],
          type: 'line',
          smooth: true,
          itemStyle: {
            color: '#0D6EFF'
          }
        },

        {
          symbol: 'none',
          name: '下单用户数',
          data: [],
          type: 'line',
          smooth: true,
          itemStyle: {
            color: '#33C6F7'
          }
        },
        {
          symbol: 'none',
          name: '子订单数',
          data: [],
          type: 'line',
          smooth: true,
          itemStyle: {
            color: '#FFAF30'
          }
        }
      ],
      optionSeries5: [
        {
          symbol: 'none',
          name: '网站总利润',
          data: [],
          type: 'line',
          smooth: true,
          itemStyle: {
            color: '#0D6EFF'
          }
        },

        {
          symbol: 'none',
          name: '下单用户数',
          data: [],
          type: 'line',
          smooth: true,
          itemStyle: {
            color: '#33C6F7'
          }
        },
        {
          symbol: 'none',
          name: '子订单数',
          data: [],
          type: 'line',
          smooth: true,
          itemStyle: {
            color: '#FFAF30'
          }
        }
      ]
    };
  },

  components: {
    PageView,
    STable,
    SmoothLine
  },
  mounted() {
    let obj = {
      name: '全部',
      value: 'all'
    };
    this.orderStatusArray.unshift(obj);
  },
  computed: {
    compareTimeTab() {
      const time1 = new Date(new Date().getTime());
      const time2 = new Date(new Date().getTime() + 24 * 60 * 60 * 1000);
      const start = moment(time1).format('YYYY-MM-DD');
      const end = moment(time2).format('YYYY-MM-DD');
      return start + '~' + end;
    }
  },
  methods: {
    moment,

    formatLineData(data) {
      if (this.currentShortcutDate == 0) {
        let arr = this.hoursRange(data);
        this.optionSeries1[0].data = arr.incomeOem;
        this.optionSeries2[0].data = arr.uid;
        this.optionSeries3[0].data = arr.count;
        // 如果是今天还要请求一次昨天的数据
        let today = new Date(new Date().toLocaleDateString()).getTime();
        let params = {
          payTimeStart: today - 24 * 60 * 60 * 1000,
          payTimeEnd: today,
          range: 'hour',
          pageNum: 1,
          pageSize: 20
        };
        orderCount(params, 3).then((res2) => {
          let arr = this.hoursRange(res2.data);
          this.optionSeries1[1].data = arr.incomeOem;
          this.optionSeries2[1].data = arr.uid;
          this.optionSeries3[1].data = arr.count;
          this.xAxisOption.xAxisData = arr.arr;
        });
      } else if (this.currentShortcutDate == 1) {
        let arr = this.hoursRange(data);
        this.optionSeries5 = JSON.parse(JSON.stringify(this.optionSeries4));
        this.optionSeries4[0].data = arr.incomeOem;
        if (this.optionSeries4[1]) {
          this.optionSeries4[1].data = arr.uid;
        }
        if (this.optionSeries4[2]) {
          this.optionSeries4[2].data = arr.count;
        }
        this.xAxisOption2.xAxisData = arr.arr;
      } else {
        // 7  30 天
        let arr = this.dayRange(data);
        this.optionSeries5 = JSON.parse(JSON.stringify(this.optionSeries4));
        this.optionSeries4[0].data = arr.incomeOem;
        if (this.optionSeries4[1]) {
          this.optionSeries4[1].data = arr.uid;
        }
        if (this.optionSeries4[2]) {
          this.optionSeries4[2].data = arr.count;
        }
        this.xAxisOption2.xAxisData = arr.dateList;
      }
    },
    hoursRange(data) {
      let newData = data.map((item) => {
        return {
          incomeOem: item.incomeOem,
          uid: Number(item.uid),
          count: item.count,
          time: Number(item.t.split(' ')[1].replace(/^0/, ' '))
        };
      });
      // 时间点数组  数据数组
      let arr = Array.from(new Array(24).keys());
      let incomeOem = new Array(24).fill(0);
      let uid = new Array(24).fill(0);
      let count = new Array(24).fill(0);

      newData.forEach((item) => {
        // 数据对象某一项的时间等于时间点数组的某一项
        // 拿到这个时间的索引
        // 作为当前索引 赋值数据
        arr.forEach((item2) => {
          incomeOem.forEach((item3) => {
            if (item.time == item2) {
              incomeOem[item2] = item.incomeOem;
            }
          });
          uid.forEach((item3) => {
            if (item.time == item2) {
              uid[item2] = item.uid;
            }
          });
          count.forEach((item3) => {
            if (item.time == item2) {
              count[item2] = item.count;
            }
          });
        });
      });
      return { arr, incomeOem, uid, count };
    },
    // 近n天
    timestampToTime,
    dayRange(data) {
      var dateTime = this.queryParam.payTimeEnd - 86400000;
      //时间数组
      var dateList = [];
      for (var i = this.currentShortcutDate - 1; i >= 0; i--) {
        dateList[i] = this.timestampToTime(dateTime);
        dateTime = dateTime - 86400000;
      }
      // --
      let newData = data.map((item) => {
        return {
          incomeOem: item.incomeOem,
          uid: Number(item.uid),
          count: item.count,
          time: item.t
        };
      });
      // 一开始置0;
      let incomeOem = new Array(this.currentShortcutDate).fill(0);
      let uid = new Array(this.currentShortcutDate).fill(0);
      let count = new Array(this.currentShortcutDate).fill(0);

      //数据对象
      newData.forEach((item, index) => {
        dateList.forEach((item2, index2) => {
          incomeOem.forEach((item3) => {
            // 数据对象某一项的时间等于时间数组的某一项
            // 拿到这个时间的索引
            // 赋值数据
            if (item.time == item2) {
              incomeOem[index2] = item.incomeOem;
            }
          });
          uid.forEach((item3) => {
            if (item.time == item2) {
              uid[index2] = item.uid;
            }
          });
          count.forEach((item3) => {
            if (item.time == item2) {
              count[index2] = item.count;
            }
          });
        });
      });
      return { dateList, incomeOem, uid, count };
    },
    //复制

    handleMouseover(record, key) {
      record[key] = true;
    },
    handleMouseout(record, key) {
      record[key] = false;
    },

    handleChartTabChange(key) {
      this.activeChartTabKey = key;
      this.optionSeries4 = JSON.parse(JSON.stringify(this.optionSeries5));
      this.formatTabRightTime();
      let today = new Date(new Date().toLocaleDateString()).getTime();
      if (key == 'previousData') {
        this.currentShortcutDate = 1;

        this.queryParam = {
          payTimeStart: today - 24 * 60 * 60 * 1000,
          payTimeEnd: today
        };
      } else {
        this.currentShortcutDate = 0;
        this.queryParam = {
          payTimeStart: today,
          payTimeEnd: today + 24 * 60 * 60 * 1000
        };
      }
      this.$refs.table.refresh(false);
    },

    handleTabChange(key) {
      this.activeTabKey = key;
      if (key == 'all') {
        const { status, ...params } = this.queryParam;
        this.queryParam = params;
      } else {
        this.queryParam.status = key;
      }
      this.$refs.table.refresh(false);
    },
    resetQueryParam() {
      this.currentShortcutDate = 0;
      this.activeChartTabKey = 'realTime';
      this.activeTabKey = 'all';
      this.queryParam = {
        payTimeStart: new Date(new Date().toLocaleDateString()).getTime(),
        payTimeEnd: new Date(new Date().toLocaleDateString()).getTime() + 24 * 60 * 60 * 1000
      };
      this.$refs.table.refresh(false);
    },
    close(id) {
      orderClose('oem', id).then((res) => {
        if (res.success) {
          this.$message.success('关闭成功');
          this.$refs.table.refresh(false);
        } else {
          this.$message.error(res.msg);
        }
      });
    },
    closeDetail(id) {
      orderDetailClose('oem', id).then((res) => {
        if (res.success) {
          this.$message.success('关闭成功');
          this.$refs.table.refresh(false);
        } else {
          this.$message.error(res.msg);
        }
      });
    },
    edit(detail) {
      this.originalData = JSON.stringify(detail);
      detail.editable = true;
    },
    handleChange(value, detail, column) {
      detail[column] = value;
    },
    onChangeDatePay(date, dateString) {
      const time = dateString.includes('');
      if (time) {
        delete this.queryParam.payTimeStart;
        delete this.queryParam.payTimeEnd;
      } else {
        this.queryParam.payTimeStart = Date.parse(new Date(dateString[0])) - 1000 * 3600 * 8;
        this.queryParam.payTimeEnd = Date.parse(new Date(dateString[1])) - 1000 * 3600 * 8;
      }
      this.currentShortcutDate = (this.queryParam.payTimeEnd - this.queryParam.payTimeStart) / 86400000;
      this.currentShortcutDate == 0 ? (this.activeChartTabKey = 'realTime') : (this.activeChartTabKey = 'previousData');
      this.formatTabRightTime();
      this.$refs.table.refresh(false);
    },
    handleShortcutDate(record) {
      this.currentShortcutDate = record.date;
      this.optionSeries4 = JSON.parse(JSON.stringify(this.optionSeries5));
      //图表tab跟随时间切换
      this.currentShortcutDate == 0 ? (this.activeChartTabKey = 'realTime') : (this.activeChartTabKey = 'previousData');
      // 今天
      let payTimeStart;
      let payTimeEnd;
      let today = new Date(new Date().toLocaleDateString()).getTime();
      if (record.date === 0) {
        payTimeStart = today;
        payTimeEnd = today + 24 * 60 * 60 * 1000;
      }
      if (record.date === 7 || record.date === 30) {
        payTimeEnd = today + 24 * 60 * 60 * 1000;
        payTimeStart = today - 24 * 60 * 60 * 1000 * (record.date - 1);
      }
      if (record.date === 1) {
        payTimeStart = today - 24 * 60 * 60 * 1000;
        payTimeEnd = today;
      }
      this.queryParam.payTimeStart = payTimeStart;
      this.queryParam.payTimeEnd = payTimeEnd;
      this.$refs.table.refresh(false);
      this.formatTabRightTime();
    },
    formatTabRightTime() {
      let time = new Date().getTime();
      this.updateTimeTab = moment(time).format('YYYY-MM-DD HH:mm:ss');
    }
  }
};
</script>

<style lang="less" scoped>
.oem_order_header {
  padding: 24px 24px 0;
  margin: -24px -24px 24px;
  background-color: #fff;
  box-shadow: 0px 2px 20px rgba(15, 41, 131, 0.1);
}
.filter_item {
  display: flex;
  align-items: center;
  margin-bottom: 24px;
  span {
    width: 56px;
    text-align: right;
  }
  .filter_input {
    width: 240px;
    margin-left: 6px;
  }
  &:nth-child(2) {
    margin-right: 0;
  }
}
.time_select {
  span {
    display: inline-block;
    margin-right: 24px;
    height: 32px;
    line-height: 32px;
    cursor: pointer;
  }
  .active {
    color: #1890ff;
  }
}
.query_btn {
  margin-right: 12px;
}
.oem_data {
  background-color: #fff;
  box-shadow: 0px 2px 20px rgba(15, 41, 131, 0.1);
  margin-bottom: 24px;
  border-radius: 4px;
  overflow-x: scroll;
  .all_chart {
    width: 1637px;
    margin-top: 8px;
    padding: 0 24px;
    display: flex;
    justify-content: space-between;
    .chart_item {
      // border: 1px solid red;
    }
    .chart_info {
      text-align: left;
      padding-left: 40px;
      .num_title {
        margin-bottom: 8px;
        font-size: 14px;
        color: rgba(0, 0, 0, 0.65);
      }
      .num_total {
        font-size: 24px;
        font-family: Roboto;
        color: rgba(0, 0, 0, 0.85);
      }
    }

    .chart_rig_num {
      display: flex;
      flex-wrap: wrap;
      // 另外加上
      .chart_info {
        width: 50%;
        padding: 0 88px;
        // text-align: center;
        margin-bottom: 40px;
        &:nth-child(1) {
          border-right: 1px solid #f0f0f0;
        }
        .num_title {
          color: rgba(0, 0, 0, 0.45);
        }
        .num_total {
          color: rgba(0, 0, 0, 0.65);
        }
        &:last-child {
          margin-bottom: 36px;
        }
      }
    }
  }

  .all_chart2 {
    width: 1637px;
    margin-top: 8px;
    display: flex;
    .num_title2 {
      margin-bottom: 8px;
      font-size: 14px;
      color: rgba(0, 0, 0, 0.65);
    }
    .num_total2 {
      font-size: 24px;
      color: rgba(0, 0, 0, 0.85);
    }
    .all_left {
      width: 64%;
      position: relative;

      .top_left {
        display: flex;
        position: absolute;
        top: 0;
        left: 64px;
        display: flex;
        .all_chart2_item {
          margin-right: 64px;
          // 公共
          // .num_title2 {
          //   margin-bottom: 8px;
          //   font-size: 14px;
          //   color: rgba(0, 0, 0, 0.65);
          //   display: flex;
          //   align-items: center;
          // }
          // .num_total2 {
          //   font-size: 24px;
          //   color: rgba(0, 0, 0, 0.85);
          // }
        }
      }
    }

    .all_right {
      margin-top: -24px;
      display: flex;
      align-items: center;
      flex-wrap: wrap;
      .right_item {
        padding: 0 55px;
        border-right: 1px solid #f0f0f0;
        .num_title2 {
          color: rgba(0, 0, 0, 0.45);
        }
        .num_total2 {
          color: rgba(0, 0, 0, 0.65);
        }
        &:nth-child(3) {
          border-right: 0;
        }
      }
    }
  }
}
.tab_time {
  line-height: 53px;
}
.oem_table_wrapper {
  background-color: #fff;
  box-shadow: 0px 2px 20px rgba(15, 41, 131, 0.1);
  margin-bottom: 24px;
  border-radius: 4px;
  .oem_table_box {
    padding: 0 24px 24px;
  }

  .circle {
    width: 8px;
    height: 8px;
    display: inline-block;
    border-radius: 50%;
    margin-right: 8px;
  }
  .color1 {
    background: rgba(0, 0, 0, 0.45);
  }
  .color2 {
    background: rgba(13, 110, 255, 1);
  }
  .color3 {
    background: rgba(82, 196, 26, 1);
  }
  .color4 {
    background: rgba(250, 173, 20, 1);
  }
  .color5 {
    background: rgba(255, 77, 79, 1);
  }
}
.uid_fat {
  position: relative;
  cursor: pointer;
  .uid_child {
    position: absolute;
    bottom: -40px;
    left: 32px;
    padding: 4px 8px;
    background-color: #f0f0f0;
    border-radius: 2px;
    color: rgba(0, 0, 0, 0.65);
    font-size: 14px;
  }
}

.top5 {
  margin-bottom: 8px;
}
//tab栏
/deep/ .ant-tabs-nav .ant-tabs-tab {
  margin: 0 16px 0 16px;
  padding: 16px 12px;
}
/deep/ .ant-tabs {
  margin-right: 24px;
}
/deep/ .ant-tabs-extra-content {
  line-height: 53px;
}
::-webkit-scrollbar-track {
  background-color: #f5f5f5;
  border-radius: 10px;
  border: 1px solid #eee;
}

::-webkit-scrollbar {
  height: 6px;
  background-color: #f5f5f5;
}

::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background-color: rgb(216, 213, 213);
}
</style>
